import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Image, Input, Space, Button, message, Tag, Empty, Spin, Form, Select} from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";

import { AppContext } from '../App';

const saleReportThisBranch = (brachCode) => {

    const { user } = useContext(AppContext);
    const codeBranch = brachCode.brachCode

    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('Authentication');
    const [form] = Form.useForm();

    const [data, setData] = useState([])
    const [yearSelected, setYearSelected] = useState('')

    const date = new Date()
    const lastmonth = new Date(date.getFullYear(), date.getMonth() - 1, 1)


  const columns = [

    {
        title: 'เดือน',
        width: 100,
        dataIndex: 'monthName',
        key: 'monthName',
        fixed: 'left',
        render: (text, record) => (
          <div>
            {text}
          </div>
        )    
    },
    {
      title: 'รายได้ทั้งหมด (บาท)',
      width: 100,
      dataIndex: 'wallet',
      key: 'wallet',
      render: (text, record) => (
        <div>
          {(record.application + record.coin).toLocaleString('en-US')}
        </div>
      )    
    },
    {
        title: 'รายได้จากหยอดเหรียญ (บาท)',
        width: 90,
        dataIndex: 'coin',
        key: 'coin',
        render: (text, record) => (
          <div>
            {text ? (text).toLocaleString('en-US') : 0}
          </div>
        ),
    },
    {
        title: 'รายได้จากแอปพลิเคชั่น (บาท)',
        width: 80,
        dataIndex: 'application',
        key: 'application',
        render: (text, record) => (
          <div>
            {text ? (text).toLocaleString('en-US') : 0}
          </div>
        ),
    }
  ]

  const changeYearSaleReport = async (value) =>{
    setYearSelected(value)
    setData('')
    fetchData(value)
  }

  const fetchData = async (year) => {

    if(!year){year = ''}
    try {
      const response = await axios.get(`${API_URL}saleReportThisBranch/${codeBranch}/${year}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      const adjustedData = response.data.map((saleReport) => ({
        ...saleReport,
        key: saleReport.monthName,
      }));


      setData(adjustedData)
    } catch (error) {
      console.log('error', error)
    }
  }


  useEffect(() => {
    if(data.length === 0 && yearSelected === ''){
        form.setFieldsValue({
            year: 2024,
        });
        fetchData()
    }
  }, [data])

  return (
    <div className="fetch-event-table">
      {
        <Form form={form} >
        <Form.Item name="year" className='formItemWithNoMargin'>
        <Select style={{width:150}} optionFilterProp="label" onChange={changeYearSaleReport}>
                <Select.Option key={2024} value={2024} >2024</Select.Option>
                <Select.Option key={2023} value={2023} >2023</Select.Option>
        </Select>
        </Form.Item>
        </Form>
      }
      { data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: 400,
          }}
          rowKey="branchCode"
        />
      ) : <center><Spin /></center>}
    </div>
  )
}

export default saleReportThisBranch